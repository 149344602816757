import { SvgIcon } from '@mui/material'
import { FC } from 'react'

export const IconGumroad: FC = () => {
  const svgPathData = [
    'M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48',
    '24ZM10.7505 23.4423C10.7505 30.4565 14.8859 36.0953 21.9162 36.0953C28.9466 36.0953 30.7385 30.4565 31.152',
    '27.5684V35.5452H36.8625V22.8923H23.8461V25.3677H30.0494C29.3601 28.3935 27.7059 31.4192 23.8461 31.4192C19.5728',
    '31.4192 16.8158 27.7058 16.8158 23.1672C16.8158 18.6287 19.5728 14.9153 23.8461 14.9153C27.8438 14.9153 29.498',
    '17.8035 29.6358 19.7289H36.1146C35.9768 16.2906 32.9441 10.2393 23.984 10.2393C15.2996 10.2393 10.7505 16.1532',
    '10.7505 23.4423Z',
  ].join(' ')

  return (
    <SvgIcon viewBox={'0 0 48 48'}>
      <path d={svgPathData} />
    </SvgIcon>
  )
}
