import { appRouter } from './AppRouter'
import { appStyles } from './AppStyles'
import { muiTheme } from './MuiTheme'
import { AppTheme } from '../../App/AppTheme'

export const zeraDarkTheme: AppTheme = {
  appRouter,
  appStyles,
  muiTheme,
}
