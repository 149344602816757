/**
 * @deprecated Use noop instead
 */
export const noOp = (): void => {
  /* noop */
}

export const noop = (): void => {
  /* noop */
}
