import { SvgIcon } from '@mui/material'
import { FC } from 'react'

export const IconBluesky: FC = () => {
  const svgPathData = [
    'M 27.491648,24.74795 C 25.000728,19.921794 18.24411,10.90778 11.954539,6.4708302 5.9296277,2.2206992',
    '3.6255274,2.9524067 2.1309758,3.6374095 0.38733236,4.43139 0.07596745,7.1091282 0.07596745,8.681521',
    'c 0,1.572393 0.8562535,12.921644 1.43227855,14.805401 1.8681895,6.258435 8.4846938,8.375716',
    '14.587446,7.690714 0.311365,-0.0467 0.62273,-0.09341 0.949663,-0.124546 -0.311365,0.0467',
    '-0.638298,0.09341 -0.949663,0.124546 C 7.1595191,32.500937 -0.79585429,35.7547 9.6348701,47.353043',
    '21.093099,59.216046 25.34323,44.815419 27.522784,37.498343 c 2.179554,7.301507 4.686042,21.188382',
    '17.669959,9.8547 9.76129,-9.8547 2.677738,-14.852106 -6.258435,-16.175407 -0.311365,-0.03114',
    '-0.638298,-0.07784 -0.949663,-0.124546 0.326933,0.0467 0.638298,0.07784 0.949663,0.124546',
    '6.102752,0.685002 12.734825,-1.432279 14.587446,-7.690714 0.560457,-1.899326 1.432279,-13.233008',
    '1.432279,-14.805401 0,-1.5723928 -0.311365,-4.250131 -2.055009,-5.0441115 C 51.388904,2.9524067',
    '49.100372,2.2206992 43.075461,6.4708302 36.739185,10.923348 29.982567,19.921794 27.491648,24.74795 Z',
  ].join(' ')

  return (
    <SvgIcon viewBox={'0 0 55 55'}>
      <path d={svgPathData} />
    </SvgIcon>
  )
}